/*jslint node: true, esversion: 6 */
"use strict";
/*globals localStorage, mod, masterUtils, _, document, angular, window, navigator, ga, gtag, ma_ut, smartlook, fbq, dataLayer, moment, TextEncoder, crypto, globalConfig */

angular
    .module('booking_form', ['booking_budget', 'booking_customer_data', 'booking_payment', 'additional_concepts'])
    .config(BookingFormConfig)
    .controller('BookingFormCtrl', BookingFormCtrl);

BookingFormConfig.$inject = ['$stateProvider'];


function reemplazarCaracteresNoLatinos(texto) {
    return texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

function sha256(text) {
    return new Promise(function (resolve, reject) {
        const data = new TextEncoder().encode(text);
        crypto.subtle.digest('SHA-256', data).then(function (hashBuffer) {
            const hashArray = Array.from(new Uint8Array(hashBuffer));
            const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
            resolve(hashHex);
        });
    });
}

function BookingFormConfig($stateProvider) {
    $stateProvider.state('bookingForm', {
        url: '/bookingForm?payment&idProduct&checkin&checkout&guestAges&facilityIds&lang&promotionCode&ra&trackref&guid&cryptoId&accRef',
        views: {
            content: {
                // templateUrl: 'templates/booking_form.html',
                templateUrl: function () {
                    const template = 'templates/booking_form.html';
                    return globalConfig.production ?
                        `${globalConfig.middlewareHost}/${globalConfig.rootName}/${template}?version=${globalConfig.version}` :
                        template;
                },
                controller: 'BookingFormCtrl'
            }
        }
    });
}

BookingFormCtrl.$inject = ['$scope', 'Bookings', 'gettextCatalog', 'loadingService', '$state', '$q', 'alertsService',
    '$window', 'ngDialog', 'myConfig', '$timeout', '$rootScope', '$cookies', '$location', '$sce', 'MyAccount'];

function BookingFormCtrl($scope, Bookings, gettextCatalog, loadingService, $state, $q, alertsService,
                         $window, ngDialog, myConfig, $timeout, $rootScope, $cookies, $location, $sce, MyAccount) {

    loadingService.enable('principal', gettextCatalog.getString("Loading"), true);

    const booking_budget_template = globalConfig.production ?
        `${globalConfig.middlewareHost}/${globalConfig.rootName}/templates/booking_budget.html?version=${globalConfig.version}` :
        'templates/booking_budget.html';
    $scope.booking_budget_template = $sce.trustAsResourceUrl(booking_budget_template);

    const booking_additional_concepts_template = globalConfig.production ?
        `${globalConfig.middlewareHost}/${globalConfig.rootName}/templates/booking_additional_concepts.html?version=${globalConfig.version}` :
        'templates/booking_additional_concepts.html';
    $scope.booking_additional_concepts_template = $sce.trustAsResourceUrl(booking_additional_concepts_template);

    const booking_customer_data_template = globalConfig.production ?
        `${globalConfig.middlewareHost}/${globalConfig.rootName}/templates/booking_customer_data.html?version=${globalConfig.version}` :
        'templates/booking_customer_data.html';
    $scope.booking_customer_data_template = $sce.trustAsResourceUrl(booking_customer_data_template);

    const booking_payment_template = globalConfig.production ?
        `${globalConfig.middlewareHost}/${globalConfig.rootName}/templates/booking_payment.html?version=${globalConfig.version}` :
        'templates/booking_payment.html';
    $scope.booking_payment_template = $sce.trustAsResourceUrl(booking_payment_template);

    const DU = masterUtils.dateUtils;

    $window.scrollTo(0, 0);

    $scope.state = 'ADDITIONAL_CONCEPTS';
    $location.hash($scope.state.toLowerCase());
    $scope.privacyPolicy = false;

    $rootScope.currentStateCss = $rootScope.currentState + " " + $scope.state;

    $scope.initialized = false;
    $scope.info = {};

    $scope.cardDenied = false;

    $scope.bookingForm = {
        sectionGroups: []
    };
    $scope.booking = {
        lang: myConfig.lang,
        navigator: {language: navigator.language || navigator.userLanguage, languages: navigator.languages},
        additionalConcepts: {},
        payment: {method: 'CREDIT_CARD'},
        rgpd: {}
    };

    $scope.lastScroll = 0;

    // CLEAN SOAP PARAMS
    const searchObject = angular.copy($location.search());
    const keysToDelete = ['Ds_Signature', 'Ds_MerchantParameters', 'Ds_SignatureVersion', 'payment'];
    Object.keys(searchObject).forEach(paramKey => {
        if (keysToDelete.includes(paramKey)) {
            $location.search(paramKey, null);
        }
    });

    $scope.showBudget = function () {
        if (angular.element("body").hasClass("showBudget")) {
            angular.element("body").removeClass("showBudget");
            angular.element("#booking_content").show();
            $window.scrollTo(0, $scope.lastScroll);
        } else {
            $scope.lastScroll = $window.pageYOffset;
            angular.element("body").addClass("showBudget");
            angular.element("#booking_content").hide();
            $window.scrollTo(0, 0);
        }
    };

    $scope.calculatedPrices = {
        totalPrice: null,
        totalImport: 0,
        totalStayImport: 0,
        downpaymentImportOptions: {},
        stayDownpaymentImport: 0,
        insuranceImport: 0,
        totalRemaining: 0,
        vatIncluded: true
    };

    if (searchObject.payment == 'ok') {
        $state.go('booking', {cryptedIdBooking: $state.params.cryptoId});
    } else if (searchObject.payment == 'ko') {
        alertsService.new(0, "payment", gettextCatalog.getString("Error in payment"));
        // TODO FER LES ACCIONS PERTINENTS
        // Bookings.cancelBooking(searchObject.cryptoId).then(() => {});
    }

    $scope.getImage = function () {
        let image = '';
        if ($scope.info.products == null) {
            return image;
        }
        const pr = _.find($scope.info.products, {idProduct: $scope.bookingFormParams.idProduct});
        if (pr.principal && pr.principal.idImage) {
            image = pr.principal.idImage;
        } else if (pr.gallery.length > 0) {
            image = pr.gallery[0].idImage;
        } else {
            const tmpWvg = _.find($scope.info.webVisualizationGroups, {idWebVisualizationGroup: pr.idWebVisualizationGroup});
            if (tmpWvg != null) {
                if (tmpWvg.principal && tmpWvg.principal.idImage) {
                    image = tmpWvg.principal.idImage;
                } else if (tmpWvg.gallery.length > 0) {
                    image = tmpWvg.gallery[0].idImage;
                }
            }
        }
        return image;
    };

    function initBooking() {

        if ($scope.bookingForm.promotionCode != null && $scope.bookingForm.promotionCode !== '') {
            $scope.product.promotionCode = $scope.bookingForm.promotionCode;
        }

        _.each($scope.bookingForm.sectionGroups, function (sg) {
            _.each(sg.sections, function (s) {
                _.each(s.additionalConcepts, function (ac) {
                    $scope.booking.additionalConcepts[ac.idAdditionalConcept] = ac.def;
                });
            });
        });

        if ($scope.experienceModeEnabled) {
            angular.element("body").addClass("experience");
            const today = DU.today();
            if ($scope.bookingFormParams.checkin == null) {
                $scope.bookingFormParams.checkin = DU.int2date(today);
            }
            if ($scope.bookingFormParams.checkout == null) {
                const checkInInt = DU.date2int($scope.bookingFormParams.checkin);
                const period = $scope.bookingForm.experience.period || 1;
                $scope.bookingFormParams.checkout = DU.int2date(checkInInt + period);
            }
            if ($scope.bookingFormParams.guestAges == null) {
                $scope.bookingFormParams.guestAges = [];
            }
            hideLineDatesOnExperienceProduct();
        }

        $scope.booking.idProduct = $scope.bookingFormParams.idProduct;
        $scope.booking.checkin = $scope.bookingFormParams.checkin;
        $scope.booking.checkout = $scope.bookingFormParams.checkout;
        $scope.booking.guestAges = $scope.bookingFormParams.guestAges;
        $scope.booking.facilityIds = $scope.bookingFormParams.facilityIds;
        $scope.booking.promotionCode = $scope.bookingFormParams.promotionCode;
        $scope.booking.accRef = $scope.bookingFormParams.accRef;
        $scope.booking.externalTrackingCode = $scope.bookingFormParams.ra || $scope.bookingFormParams.trackref;
        $scope.initialized = true;

        $scope.accompanyingRequired = myConfig.accompanyingRequired;

        $timeout(function () {
            angular.element('.showInputDescription').bind("click", function (event) {
                const scope = angular.element(event.currentTarget.parentElement).scope();

                let description = '';
                if (scope.ngAdditionalConcept) description = scope.ngAdditionalConcept.description;
                if (scope.option) description = scope.option.description;
                if (scope.sectionGroup) description = scope.sectionGroup.description;
                if (scope.section) description = scope.section.description;

                ngDialog.open({
                    template: 'moreInfoPopupTemplate',
                    className: 'ngdialog-theme-default html_content largeDialog',
                    controller: ["$scope", function ($childScope) {
                        $childScope.description = description;
                    }]
                });
            });

        }, 1000);

        $timeout(function () {
            const price = ($scope.calculatedPrices.totalImport / 1.1).toFixed(2);
            const tax = ($scope.calculatedPrices.totalImport / 1.1) * (0.1).toFixed(2);
            if (myConfig.facebookPixelCode && typeof fbq !== "undefined") {
                fbq('track', 'InitiateCheckout', {
                    value: ($scope.calculatedPrices.totalImport / 1.1).toFixed(2),
                    currency: 'EUR',
                    content_name: $scope.product.name,
                    content_ids: [$scope.product.idProduct],
                    content_type: $scope.wvg.name
                });
            }
            if (typeof dataLayer !== 'undefined' && Array.isArray(dataLayer)) {
                const dataEvent = {price, tax};
                Object.keys($scope.bookingFormParams).forEach((key) => {
                    if ($scope.bookingFormParams[key] != null) {
                        dataEvent[key] = $scope.bookingFormParams[key];
                    }
                    if (['checkin', 'checkout'].includes(key) && dataEvent[key] != null &&
                        typeof dataEvent[key].toISOString === 'function') {
                        dataEvent[key] = moment(dataEvent[key]).format('YYYY-MM-DD');
                    }
                });

                if (myConfig.GoogleTagManagerVersion == null || myConfig.GoogleTagManagerVersion == 1) {
                    dataEvent.event = 'bookingForm';
                    dataLayer.push(dataEvent);
                }

                if (myConfig.GoogleTagManagerVersion == 2) {

                    dataLayer.push({ecommerce: null, bookingData: null});

                    let price = $scope.calculatedPrices.totalImport.toFixed(2);
                    const touristTax = $scope.calculatedPrices.touristicTax;
                    const priceWithoutTouristTax = $scope.calculatedPrices.totalImport - touristTax;
                    const bookingTax = (priceWithoutTouristTax / 1.1) * (0.1);
                    let tax = (bookingTax + touristTax).toFixed(2);
                    if (typeof price === 'string') {
                        price = parseFloat(price);
                    }
                    if (typeof tax === 'string') {
                        tax = parseFloat(tax);
                    }
                    const product = Object.assign({}, $scope.product, {facilityIds: $state.params.facilityIds});
                    const gtmProduct = Bookings.processGtmProduct(product, null, {price, tax});
                    dataEvent.price = price;
                    dataEvent.tax = tax;

                    dataLayer.push({
                        event: 'add_to_cart',
                        ecommerce: {
                            items: [
                                gtmProduct
                            ]
                        }
                    });
                    dataLayer.push({
                        event: 'begin_form',
                        bookingData: dataEvent,
                        ecommerce: {
                            items: [
                                gtmProduct
                            ]
                        }
                    });
                    dataLayer.push({
                        event: "form_step",
                        step: $scope.state.toLowerCase()
                    });
                }

            }
        }, 2000);

        if ($state.params.cryptoId != null) {
            loadFormFromBookingData($state.params.cryptoId);
        }

        let edmTenantId = null;
        if ($scope.info.myAccount != null && $scope.info.myAccount.tenantId) {
            edmTenantId = $scope.info.myAccount.tenantId;
        }

        if (($scope.bookingForm.data != null && $scope.bookingForm.data.holder != null) || edmTenantId != null) {
            const data = $scope.bookingForm.data || {holder: {}};
            if (edmTenantId != null) {
                const person = MyAccount.getFormattedData();
                if (person != null) {
                    Object.keys(person).forEach(key => {
                        if (person[key] != null && person[key] !== '') {
                            data.holder[key] = person[key];
                        }
                    });
                    processFormForEdm();
                }
            }
            setFormData(data);
            $scope.booking.mergedIdClient = (data.holder != null && data.holder.idClient) ? data.holder.idClient : null;
            $scope.booking.idClientGroup = MyAccount.getMyAccountPersonId();
        }


        $scope.allDataLoaded = true;
        setGuestConceptsFromSections();
        $cookies.remove('booking_ecommerce_send');

        if ($scope.paymentOptionsLength === 1 && $scope.paymentMethod === 'ATCHECKIN') {
            $scope.nextMessage = gettextCatalog.getString("End Reservation Request");
            $scope.nexLabel = gettextCatalog.getString("Reservation request");
        }

        loadingService.disable('principal');
    }

    if ($state.params.facilityIds) {
        $state.facilityIds = $state.params.facilityIds.split(",");
    }

    $scope.bookingFormParams = {
        idProduct: parseInt($state.params.idProduct),
        promotionCode: $state.params.promotionCode,
        ra: $state.params.ra,
        trackref: $state.params.trackref,
        guid: $state.params.guid
    };

    if ($state.params.checkin != null) {
        $scope.bookingFormParams.checkin = new Date($state.params.checkin + "T00:00:00.000Z");
    }

    if ($state.params.checkout != null) {
        $scope.bookingFormParams.checkout = new Date($state.params.checkout + "T00:00:00.000Z");
    }

    if ($state.params.guestAges != null) {
        $scope.bookingFormParams.guestAges = $state.params.guestAges
            .split(",")
            .map(function (age) {
                return parseInt(age, 10);
            });
    }

    if ($state.params.facilityIds != null) {
        $scope.bookingFormParams.facilityIds = $state.params.facilityIds ? $state.params.facilityIds.split(",") : [];
    }

    if ($state.params.lang) {
        myConfig.lang = $state.params.lang;
    }

    if ($state.params.accRef) {
        $scope.bookingFormParams.accRef = $state.params.accRef;
    }

    Bookings.getInfo()
        .then(function (info) {
            $scope.info = _.cloneDeep(info);
            const product = _.findWhere($scope.info.products, {idProduct: $scope.bookingFormParams.idProduct});
            $scope.product = _.cloneDeep(product);
            if ($scope.product) {
                if ($scope.product.extraOptions != null && $scope.product.extraOptions.experienceModeEnabled === true) {
                    $scope.experienceMode = $scope.product.extraOptions.mode;
                    $scope.experienceModeEnabled = true;
                }
                if ($scope.product.conditions != null && $scope.product.conditions !== '') {
                    if ($scope.info.contents.Contract != null && $scope.info.contents.Contract !== '') {
                        $scope.info.contents.Contract = $scope.product.conditions + '<hr style="margin: 20px;">' + $scope.info.contents.Contract;
                    } else {
                        $scope.info.contents.Contract = $scope.product.conditions;
                    }
                }
                $scope.wvg = _.findWhere($scope.info.webVisualizationGroups, {idWebVisualizationGroup: $scope.product.idWebVisualizationGroup});
            }

            $scope.info.rgpd
                .filter(rgpd => ($scope.product.isExperience) ? rgpd.apply_experiences : rgpd.apply_master_booking)
                .forEach(rgpd => {
                    if ($scope.booking.rgpd[rgpd.idConsent] == null) {
                        $scope.booking.rgpd[rgpd.idConsent] = false;
                    }
                });

            if (info.myAccount != null && info.myAccount.tenantId != null) {
                const {idClientGroup, email, lastName} = MyAccount.getFormattedData() || {};
                $scope.bookingFormParams.idClientGroup = idClientGroup;
                $scope.bookingFormParams.email = email;
                $scope.bookingFormParams.lastName = lastName;
            }

            return Bookings.getBookingForm($scope.bookingFormParams);

        })
        .then((bookingForm) => {

            // SET NUL VALUES FOR REQUIRED INPUTS
            bookingForm.form.sections.guest.inputs.forEach(input => {
                if (input.required && input.value.length < bookingForm.form.sections.guest.counter) {
                    while (input.value.length < bookingForm.form.sections.guest.counter) {
                        input.value.push(null);
                    }
                }
            });

            // clean empty sectionGroups
            bookingForm.sectionGroups = bookingForm.sectionGroups.filter(sectionGroup => {
                return sectionGroup.sections.length > 0;
            });

            $scope.bookingForm = _.cloneDeep(bookingForm);

            if (bookingForm.sectionGroups.length <= 1) {
                $scope.state = 'CUSTOMER_DATA';
                $location.hash($scope.state.toLowerCase());
                $rootScope.currentStateCss = $rootScope.currentState + " " + $scope.state;
            }

            /**
             *  Init payment variables
             */

            let dpo = _.findWhere($scope.bookingForm.downpaymentOptions, {order: 1});

            if (!dpo) {
                //dpo = $scope.bookingForm.downpaymentOptions[0];
                dpo = $scope.bookingForm.downpaymentOptions[Object.keys($scope.bookingForm.downpaymentOptions)[0]];
            }

            $scope.booking.payment.idPaymentConditionRule = dpo.idPaymentConditionRule;
            $scope.paymentMethod = dpo.paymentClass;
            $scope.paymentOptionsLength = Object.keys($scope.bookingForm.downpaymentOptions).length;
            $scope.paymentMethodLongDescription = dpo.longDescription;

            if (dpo.paymentClass === "CREDIT_CARD" || dpo.paymentClass === "CREDITCARD_TPVPC" || dpo.paymentClass === "CREDITCARD_SAVE") {
                $scope.creditCardDefault = dpo.paymentClass;
                $scope.creditCardDefaultName = dpo.name;
            }

            $scope.getPaymentMethodName = function (dpoItem) {
                if (dpoItem.shortDescription[$scope.lang]) {
                    return dpoItem.shortDescription[$scope.lang];
                } else {
                    if (dpoItem.paymentClass === "BANKTRANSFER") {
                        return gettextCatalog.getString("I'll pay by transfer");
                    }
                    if (dpoItem.paymentClass === "ATCHECKIN") {
                        return gettextCatalog.getString("I'll pay at checkin");
                    }
                    return gettextCatalog.getString("Credit card");
                }
            };

            $scope.selectMethod = function (dpoItem) {
                $scope.booking.payment = {};
                $scope.paymentMethod = dpoItem.paymentClass;
                $scope.paymentMethodLongDescription = dpoItem.longDescription;
                $scope.booking.payment.idPaymentConditionRule = dpoItem.idPaymentConditionRule;
            };

            $scope.downpaymentOptions = _.sortBy($scope.bookingForm.downpaymentOptions, function (item) {
                return item.order;
            });

            $scope.downpaymentOptionsKeys = Object.keys($scope.bookingForm.downpaymentOptions);

            _.each($scope.bookingForm.downpaymentOptions, function (pm) {
                if (pm.paymentClass === "CREDIT_CARD" || pm.paymentClass === "CREDITCARD_TPVPC" || pm.paymentClass === "CREDITCARD_SAVE") {
                    angular.element(".tarjeta-wrapper").show();
                    angular.element(".CREDIT_CARD_box").show();
                }
                if (pm.paymentClass === "BANKTRANSFER") {
                    angular.element(".transferencia-wrapper").show();
                    angular.element(".BANKTRANSFER_box").show();
                }
                if (pm.paymentClass === "ATCHECKIN") {
                    angular.element(".atcheckin-wrapper").show();
                    angular.element(".ATCHECKIN_box").show();
                }
            });

            $scope.getPaymentMethod = function (paymentClass) {
                var paymentMethod = false;
                var downpaymentOptionsKeys = Object.keys($scope.bookingForm.downpaymentOptions);
                downpaymentOptionsKeys.forEach(function (pmKey) {
                    var pm = $scope.bookingForm.downpaymentOptions[pmKey];
                    if (pm.paymentClass === paymentClass) paymentMethod = pm;
                });
                return paymentMethod;
            };

            $scope.isConsentInvalid = function (consent) {
                return consent.required &&
                    $scope.payment_form['rgpd_' + consent.idConsent].$invalid &&
                    ($scope.payment_form_submitted || !$scope.payment_form['rgpd_' + consent.idConsent].$pristine);
            };

        })
        .then(() => {
            $timeout(() => {
                initBooking();
            }, 200);
        })
        .catch((err) => {
            if (typeof err === 'string' && err.includes('not available')) {
                alertsService.new('bookingForm', 'warning', err, 5000);
            } else {
                alertsService.newError("Unexpected error in service", err);
            }
            $state.go('search', {
                checkin: $state.params.checkin,
                checkout: $state.params.checkout,
                guestAges: $state.params.guestAges
            });
        });

    $scope.verifyRequireds = [];

    $scope.reviewMinAgeRequiredValues = function (input, age, key) {
        var inputs = $scope.bookingForm.form.sections.holder.inputs;
        var requiredFlag = "holder_0";
        if (input.idSection === 2) {
            inputs = $scope.bookingForm.form.sections.guest.inputs;
            requiredFlag = "guest_" + key;
        }
        _.each(inputs, function (eachInput) {
            if (eachInput.schema && eachInput.schema.minAge) {
                if (age < eachInput.schema.minAge) {
                    $scope.verifyRequireds[requiredFlag] = age;
                } else {
                    delete $scope.verifyRequireds[requiredFlag];
                }
            }
        });
    };

    function hideLineDatesOnExperienceProduct() {
        let hideFromDate = false;
        let hideToDate = false;
        if ($scope.experienceMode === 'withoutDates') {
            hideFromDate = true;
            hideToDate = true;
        }
        if ($scope.experienceMode === 'withCheckInDate') {
            hideToDate = true;
        }
        if ($scope.bookingForm.basePrice && Array.isArray($scope.bookingForm.basePrice.lines)) {
            $scope.bookingForm.basePrice.lines.forEach(line => {
                line.hideFromDate = hideFromDate;
                line.hideToDate = hideToDate;
            });
        }
    }

    $scope.computeTouristTax = function () {
        const lines = $scope.bookingForm ? $scope.bookingForm.basePrice ? $scope.bookingForm.basePrice.lines : null : null;
        if (lines == null) {
            return 0;
        }
        const conceptLines = lines.filter(line => line.idConcept != null);
        const touristTaxLines = conceptLines.filter(line => line.attributes.includes('TURISTICTAX'));
        const prices = touristTaxLines.map(line => {
            let result = line.price * line.quantity;
            if (line.taxes != null && line.taxes.length > 0) {
                const vat = line.taxes.find(tax => tax.type === "VAT");
                const ratio = vat != null ? vat.PC / 100 : 1;
                result = result * (1 + ratio);
            }
            return result;
        });
        return prices.reduce((acc, price) => acc + price, 0);
    };

    $scope.getPrice = function (additionalConcepts) {

        if (!$scope.initialized) return null;

        $scope.booking.guestAges = $scope.bookingFormParams.guestAges;
        $scope.calculatedPrices.vatIncluded = myConfig.vatIncluded;

        hideLineDatesOnExperienceProduct();

        return Bookings.getPrice({
            checkin: $scope.bookingFormParams.checkin,
            checkout: $scope.bookingFormParams.checkout,
            guestAges: $scope.bookingFormParams.guestAges,
            baseName: $scope.product.name,
            showBasePriceDetail: $scope.bookingForm.showBasePriceDetail,
            vatIncluded: $scope.bookingForm.vatIncluded,
            basePrice: $scope.bookingForm.basePrice,
            sectionGroups: $scope.bookingForm.sectionGroups,
            additionalConcepts,
            gettextCatalog
        });

    };

    $scope.calculate = function () {

        angular.copy({
            totalPrice: null,
            totalImport: 0,
            totalStayImport: 0,
            downpaymentImportOptions: {},
            stayDownpaymentImport: 0,
            insuranceImport: 0,
            payAtCheckin: 0
        }, $scope.calculatedPrices);

        if (!$scope.initialized) return;

        var budget = $scope.getPrice($scope.booking.additionalConcepts);

        $scope.calculatedPrices.totalPrice = budget;


        $scope.calculatedPrices.downpaymentImportOptions = {};

        _.each($scope.bookingForm.downpaymentOptions, function (downpaymentOption) {
            $scope.calculatedPrices.downpaymentImportOptions[downpaymentOption.idPaymentConditionRule] = downpaymentOption;
        });

        let downpaymentOption = null;
        if ($scope.calculatedPrices.downpaymentImportOptions[$scope.booking.payment.idPaymentConditionRule]) {
            downpaymentOption = $scope.calculatedPrices.downpaymentImportOptions[$scope.booking.payment.idPaymentConditionRule];
        } else {
            let u = _.uniq(_.values($scope.calculatedPrices.downpaymentImportOptions));
            if (u.length === 1) {
                downpaymentOption = $scope.calculatedPrices.downpaymentImportOptions[u[0]];
            }
        }

        if (downpaymentOption) {
            budget.addPrice(downpaymentOption.price);

            $scope.calculatedPrices.paymentPriceRender = budget.renderTree("total", masterUtils.Price2.attrFilter("PAY_RESERVATION"), {ifOneHideChild: false});

            $scope.calculatedPrices.paymentPriceRender.label = gettextCatalog.getString('Total downpayment');
            $scope.calculatedPrices.paymentPriceRender.expanded = true;
        }

        $scope.calculatedPrices.stayPriceRender = budget.renderTree("total", masterUtils.Price2.attrFilter("IN_BUDGET"));
        $scope.calculatedPrices.touristicTaxRender = budget.renderTree("total", (n) => {
            return (n.childs || ["IN_BUDGET", "TURISTICTAX"].every(attr => n.attributes.includes(attr)));
        });
        $scope.calculatedPrices.stayPriceRender.label = gettextCatalog.getString('Total');
        $scope.calculatedPrices.stayPriceRender.expanded = true;

        $scope.calculatedPrices.totalImport = $scope.calculatedPrices.stayPriceRender.import;
        $scope.calculatedPrices.touristicTax = $scope.calculatedPrices.touristicTaxRender.import;
        $scope.calculatedPrices.paymentImport = $scope.calculatedPrices.paymentPriceRender.import;

        // $scope.calculatedPrices.payAtCheckin = $scope.calculatedPrices.stayPriceRender.import - $scope.calculatedPrices.paymentPriceRender.import;
        var payAtCheckin = getPayAtCheckin(budget);

        $scope.calculatedPrices.payAtCheckinRender = payAtCheckin.renderTree("total", null, {ifOneHideChild: false});
        $scope.calculatedPrices.payAtCheckinRender.label = gettextCatalog.getString('@TOTAL_REMAINING');
        $scope.calculatedPrices.payAtCheckinRender.expanded = true;

        $scope.calculatedPrices.totalRemaining = $scope.calculatedPrices.payAtCheckinRender.import;

        if ($scope.firstCount == null) {
            $scope.firstCount = $scope.bookingForm.form.sections.vehicles.counter;
        }
        $scope.bookingForm.form.sections.vehicles.counter = $scope.calculatedPrices.totalPrice.lines.reduce((result, line) => {
            if (line.attributes && line.attributes.includes('CONCEPT')) {
                line.quantity = (line.quantity == null || isNaN(line.quantity)) ? 1 : line.quantity;
                if (line.from == null) {
                    result = result + (line.quantity * line.includedVehicles);
                } else if (new Date(line.from).getTime() === $scope.booking.checkin.getTime()
                ) {
                    result = result + (line.quantity * line.includedVehicles);
                }
            }
            return result;
        }, $scope.firstCount);

    };

    function getPayAtCheckin(budget) {
        const result = new masterUtils.Price2(budget.options);
        let newL;

        budget.forEachLead(function (l) {
            if (_.contains(l.attributes, "DOWNPAYMENT")) {
                if (_.contains(l.attributes, 'PAY_RESERVATION')) {
                    newL = _.cloneDeep(l);
                    newL.price = -l.basePrice;
                    newL.attributes.push("PENDING");
                    result.addPrice(newL);
                } else {
                    if (_.contains(l.attributes, 'NO_PAY')) {
                        // We do not insert concepts with NO_PAY
                    } else {
                        newL = _.cloneDeep(l);
                        newL.price = l.basePrice;
                        result.addPrice(newL);
                    }
                }
            } else {
                if (_.contains(l.attributes, 'PAY_RESERVATION')) {
                    // If payed, then it is not pending.
                } else {
                    if (_.contains(l.attributes, 'NO_PAY')) {
                        // We do not insert concepts with NO_PAY
                    } else {
                        if (_.contains(l.attributes, "IN_BUDGET")) {
                            newL = _.cloneDeep(l);
                            newL.price = l.basePrice;
                            newL.attributes.push("PENDING");
                            result.addPrice(newL);
                        } else {
                            newL = _.cloneDeep(l);
                            newL.price = l.basePrice;
                            result.addPrice(newL);
                        }
                    }
                }
            }
        });

        if (myConfig.vatIncluded) {
            result.addPrice({
                class: "VATINCLUDED"
            });
        } else {
            result.addPrice({
                class: "VAT"
            });
        }
        result.addPrice({
            id: "pending",
            class: "AGREGATOR",
            label: gettextCatalog.getString('@BUDGET_REMAINING'),

            hideDetail: true,
            expanded: false,
            collapsable: true,

            groupBy: "PENDING",
            execOrder: 60,
            hideIfNoChilds: false,
            showIfZero: false,

            attributes: [],

            order: 0
        });
        return result;
    }

    let budgedChanges = null;
    $scope.$watch("booking", function () {

        if (!$scope.initialized) {
            return;
        }

        // TODO Verificar el numero de llamadas a esta funcion
        recalculateExperiencesGuestAges();
        $scope.calculate();
        $scope.$broadcast('calculate');

        /*
        let bookingCopy = _.cloneDeep($scope.booking);
        if (bookingCopy.payment != null) {
            delete bookingCopy.payment.ccNumber;
            delete bookingCopy.payment.ccExpirationDate;
            delete bookingCopy.payment.ccCvc;
        }
        delete bookingCopy.rgpd;
        bookingCopy = JSON.stringify(bookingCopy);
        if (budgedChanges == null || budgedChanges !== bookingCopy) {
            $scope.calculate();
            $scope.$broadcast('calculate');
            budgedChanges = bookingCopy;
        }
        */
    }, true);

    const guestConceptIndex = {
        adults: {},
        children: {}
    };

    function processLines(referenceKey, lines) {
        for (const line of lines) {
            if (line.attributes != null && line.attributes.includes('ADULT')) {
                if (guestConceptIndex.adults[referenceKey] == null) {
                    guestConceptIndex.adults[referenceKey] = 1;
                } else {
                    guestConceptIndex.adults[referenceKey]++;
                }
            }
            const childrenTags = ['CHILD', 'TEENAGER'];
            if (line.attributes != null && line.attributes.some(attribute => childrenTags.includes(attribute))) {
                if (guestConceptIndex.children[referenceKey] == null) {
                    guestConceptIndex.children[referenceKey] = 1;
                } else {
                    guestConceptIndex.children[referenceKey]++;
                }
            }
        }
    }

    function setGuestConceptsFromSections() {
        for (const sectionGroup of $scope.bookingForm.sectionGroups) {
            for (const section of sectionGroup.sections) {
                for (const additionalConcept of section.additionalConcepts) {
                    if (additionalConcept.type === 'SINGLE_CHOICE') {
                        for (const option of Object.values(additionalConcept.options)) {
                            if (option.price && Array.isArray(option.price.lines)) {
                                processLines(option.idOption, option.price.lines);
                            }
                        }
                    }
                    if (additionalConcept.unitPrice && Array.isArray(additionalConcept.unitPrice.lines)) {
                        processLines(additionalConcept.idAdditionalConcept, additionalConcept.unitPrice.lines);
                    }
                }
            }
        }
    }

    function recalculateExperiencesGuestAges() {
        if ($scope.experienceModeEnabled) {
            const newGuestAges = [];
            for (const additionalConcept of Object.entries($scope.booking.additionalConcepts)) {
                const idAdditionalConcept = additionalConcept[0];
                const additionalValue = additionalConcept[1];
                if (additionalValue == null) continue;
                let adultsCounter = guestConceptIndex.adults[idAdditionalConcept] || 0;
                let childrenCounter = guestConceptIndex.children[idAdditionalConcept] || 0;
                if (typeof additionalValue === 'boolean' && additionalValue) {
                    adultsCounter = additionalValue ? adultsCounter : 0;
                    childrenCounter = additionalValue ? childrenCounter : 0;
                }
                if (typeof additionalValue === 'number' || typeof additionalValue === 'string') {
                    adultsCounter = adultsCounter * additionalValue;
                    childrenCounter = childrenCounter * additionalValue;
                }
                for (let iAdult = 0; iAdult < adultsCounter; iAdult++) {
                    newGuestAges.push(18);
                }
                for (let iChildren = 0; iChildren < childrenCounter; iChildren++) {
                    newGuestAges.push(17);
                }
            }

            for (const line of $scope.bookingForm.basePrice.lines) {
                if ($scope.booking.checkin.getTime() !== new Date(line.from).getTime()) {
                    continue;
                }
                if (line.attributes != null && line.attributes.includes('ADULT')) {
                    for (let iAdult = 0; iAdult < line.quantity; iAdult++) {
                        newGuestAges.push(18);
                    }
                }
                if (line.attributes != null && line.attributes.includes('CHILD')) {
                    for (let iChildren = 0; iChildren < line.quantity; iChildren++) {
                        newGuestAges.push(17);
                    }
                }
            }
            // $scope.bookingFormParams.guestAges = newGuestAges;
            $scope.bookingForm.form.sections.guest.counter = newGuestAges.length - 1;
        }
    }

    $scope.isActive = function (state) {
        if ($scope.state === state) return 'active';
        return '';
    };

    /**
     * Bugget buttons controller
     */
    $scope.nexLabel = gettextCatalog.getString("Continue");
    $scope.backMessage = gettextCatalog.getString("Search accommodation");
    $scope.nextMessage = gettextCatalog.getString("Customer data");

    function goBack() {
        const stateParams = {
            checkin: $state.params.checkin,
            checkout: $state.params.checkout,
            guestAges: $state.params.guestAges,
            facilityIds: $state.params.facilityIds,
            promotionCode: $state.params.promotionCode,
            lang: $state.params.lang
        };
        $state.go('search', stateParams);
    }

    $scope.returnBack = function () {
        switch ($scope.state) {
            case 'ADDITIONAL_CONCEPTS':
                $scope.backMessage = gettextCatalog.getString("Search accommodation");
                $scope.nextMessage = gettextCatalog.getString("Customer data");
                goBack();
                break;
            case 'CUSTOMER_DATA':
                $scope.nexLabel = gettextCatalog.getString("Continue");
                $scope.backMessage = gettextCatalog.getString("Additional concepts");
                $scope.nextMessage = gettextCatalog.getString("Customer data");
                if ($scope.bookingForm.sectionGroups.length <= 1) {
                    goBack();
                } else {
                    $scope.state = 'ADDITIONAL_CONCEPTS';
                }
                break;
            case 'PAYMENT':
                $scope.nexLabel = gettextCatalog.getString("Continue");
                $scope.backMessage = gettextCatalog.getString("Additional concepts");
                $scope.nextMessage = gettextCatalog.getString("Customer data");
                $scope.state = 'CUSTOMER_DATA';
                break;
        }
        $window.scrollTo(0, 0);
        $rootScope.currentStateCss = $rootScope.currentState + " " + $scope.state;
        $location.hash($scope.state.toLowerCase());
        if (typeof dataLayer !== 'undefined' && Array.isArray(dataLayer) && myConfig.GoogleTagManagerVersion == 2) {
            dataLayer.push({
                event: "form_step",
                step: $scope.state.toLowerCase()
            });
        }
    };

    $scope.isSGVisible = function (sectionGroup) {
        return !!_.find(sectionGroup.sections, function (section) {
            return !!_.find(section.additionalConcepts, function (additionalConcept) {
                return $scope.isACVisible(additionalConcept);
            });
        });
    };

    /*jslint evil: true */
    $scope.isACVisible = function (additionalConcept) {

        if (!additionalConcept.isVisible) return true;
        var v;
        var S = "\"use strict\"; \n";
        S += "var booking = " + JSON.stringify($scope.booking) + ";\n";
        S += "var value =" + JSON.stringify($scope.booking.additionalConcepts[additionalConcept.idAdditionalConcept]) + ";\n";
        S += "var totalBudget =" + JSON.stringify($scope.calculatedPrices.totalImport) + ";\n";
        S += "var totalPayment =" + JSON.stringify($scope.calculatedPrices.paymentImport) + ";\n";
        S += "var totalRemaining =" + JSON.stringify($scope.calculatedPrices.totalRemaining) + ";\n";
        S += "var params =" + JSON.stringify($state.params) + ";\n";
        S += "var lang ='" + $scope.lang + "';\n";

        S += additionalConcept.isVisible;

        try {
            v = eval(S);
        } catch (err) {
            v = false;
        }

        var visible = (v === false) ? false : true;

        if (!visible) {
            $scope.booking.additionalConcepts[additionalConcept.idAdditionalConcept] = additionalConcept.def;
        }

        return visible;
    };

    $scope.getACInvalidMsg = function (additionalConcept) {

        if (!additionalConcept.validate) return null;

        var errStr = null;
        var S = "\"use strict\"; \n";
        S += "var booking = " + JSON.stringify($scope.booking) + ";\n";
        S += "var value =" + JSON.stringify($scope.booking.additionalConcepts[additionalConcept.idAdditionalConcept]) + ";\n";
        S += "var totalBudget =" + JSON.stringify($scope.calculatedPrices.totalImport) + ";\n";
        S += "var totalPayment =" + JSON.stringify($scope.calculatedPrices.paymentImport) + ";\n";
        S += "var totalRemaining =" + JSON.stringify($scope.calculatedPrices.totalRemaining) + ";\n";
        S += "var params =" + JSON.stringify($state.params) + ";\n";
        S += "var lang ='" + $scope.lang + "';\n";

        S += additionalConcept.validate;

        try {
            eval(S);
        } catch (err) {
            if (err instanceof Error) {
                errStr = err.message;
            } else {
                errStr = err.toString();
            }
        }

        if (errStr) {
            if (errStr.toUpperCase == "REQUIRED") errStr = gettextCatalog.getString("Required");
            if (errStr.toUpperCase == "INVALID") errStr = gettextCatalog.getString("Invalid");
        }
        return errStr;
    };

    function additionalConceptsAreValid() {
        let valid = true;
        _.each($scope.bookingForm.sectionGroups, function (sectionGroup) {
            _.each(sectionGroup.sections, function (section) {
                _.each(section.additionalConcepts, function (additionalConcept) {
                    if ($scope.isACVisible(additionalConcept) && ($scope.getACInvalidMsg(additionalConcept) !== null)) valid = false;
                });
            });
        });
        return valid;
    }

    $scope.nextStep = function () {
        switch ($scope.state) {
            case 'ADDITIONAL_CONCEPTS':
                $scope.additional_concepts_form_submitted = true;
                if (($scope.additional_concepts_form.$valid) && (additionalConceptsAreValid())) {
                    $scope.backMessage = gettextCatalog.getString("Additional concepts");
                    $scope.nextMessage = gettextCatalog.getString("Payment Methods");
                    $scope.state = 'CUSTOMER_DATA';

                    if ($scope.paymentOptionsLength === 1 && $scope.paymentMethod === 'ATCHECKIN') {
                        $scope.nextMessage = gettextCatalog.getString("End Reservation Request");
                        $scope.nexLabel = gettextCatalog.getString("Reservation request");
                    }

                }
                $window.scrollTo(0, 0);
                break;
            case 'CUSTOMER_DATA':
                $scope.customer_data_form.$setSubmitted(true);
                $scope.customer_data_form_submitted = true;
                $scope.additional_concepts_form_submitted = true;

                if (($scope.customer_data_form.$valid) && (additionalConceptsAreValid())) {
                    if ($scope.paymentOptionsLength === 1 && $scope.paymentMethod === 'ATCHECKIN') {
                        $scope.submitBookingForm();
                    } else {
                        $scope.backMessage = gettextCatalog.getString("Customer data");
                        if ($scope.paymentMethod === 'ATCHECKIN') {
                            $scope.nextMessage = gettextCatalog.getString("End Reservation Request");
                        } else {
                            $scope.nextMessage = gettextCatalog.getString("End Booking");
                        }
                        $scope.state = 'PAYMENT';
                        $scope.nexLabel = gettextCatalog.getString("Buy");
                    }
                }
                $window.scrollTo(0, 0);
                break;
        }
        $rootScope.currentStateCss = $rootScope.currentState + " " + $scope.state;
        $location.hash($scope.state.toLowerCase());
        if (typeof dataLayer !== 'undefined' && Array.isArray(dataLayer) && myConfig.GoogleTagManagerVersion == 2) {
            dataLayer.push({
                event: "form_step",
                step: $scope.state.toLowerCase()
            });
        }
    };

    let reserving = false;

    function processError(err) {
        reserving = false;

        if (err) {

            console.error(err);

            const errorCode = err.code || err.errorCode;
            const errorMessage = err.message || err.errorMessage || '';

            if (window._loq) {
                window._loq.push(["tag", "Error"]);
                if (errorCode) {
                    window._loq.push(["tag", errorCode]);
                } else {
                    window._loq.push(["tag", "UndefinedError"]);
                }
            }

            if (errorCode && errorCode == "bank.paymentError") {
                $scope.cardDenied = true;
                if ((err.message && err.message.indexOf("DENEGADA") !== -1) || (err.errorMsg && err.errorMsg.indexOf("DENEGADA") !== -1)) {
                    $scope.booking.payment.ccNumber = null;
                    $scope.booking.payment.ccCvc = null;
                    alertsService.new('bookingForm', 'error', gettextCatalog.getString("The card has been denied."));
                } else {
                    alertsService.newError("Unexpected error in payment", err.message || err.errorMsg, true);
                }
            } else if (err.code === 'booking.notAvailable') {
                alertsService.new(err.code, "warning", err.message);
                $state.go('search', {
                    checkin: $state.params.checkin,
                    checkout: $state.params.checkout,
                    guestAges: $state.params.guestAges
                });
            } else if (errorCode === 'booking.preparePayment') {
                alertsService.new(err.code, "warning", err.message);
                if (err.message.includes('Unavailable stock')) {
                    $state.go('search', {
                        checkin: $state.params.checkin,
                        checkout: $state.params.checkout,
                        guestAges: $state.params.guestAges
                    });
                }
            } else if (err.code === 'bookings.validate') {
                alertsService.new(err.code, "warning", err.message);
            } else if (err.code === 'input.invalidPromotionalCode') {
                alertsService.new('advise', 'warning', gettextCatalog.getString('Please, check the promotional code, refresh the page and try again.'));
            } else if (err.code === 'makeBooking.duplicated') {
                alertsService.new("BookingDuplicated", "warning", gettextCatalog.getString("Please, we have encountered a problem with payment and have blocked previous reservation.\n Contact to manage the booking.\n") + err.message);
                return;
            } else {
                const unexpectedErrorMessage = gettextCatalog.getString('Error in the payment process. As a precaution, it is recommended to check if the bank account has been charged or an email has been received with the booking confirmation. In this case, contact the property to verify your reservation.');
                alertsService.newError("Unexpected error in service", err.message || err.errorMsg);
                alertsService.new(errorCode, "warning", unexpectedErrorMessage);
            }
        } else {
            alertsService.newError("Unexpected error in service", "Payment declined, please contact if the error repeats.");
        }
        loadingService.disable('principal');
    }

    function saveBookingDataInCookies(bookingData) {
        const expireDate = new Date();
        expireDate.setDate(expireDate.getDate() + 1);
        const cookieOptions = {expires: expireDate};

        $cookies.put('booking_language', bookingData.lang, cookieOptions);
        $cookies.put('booking_idBooking', bookingData.idBooking, cookieOptions);
        $cookies.put('booking_productName', bookingData.productName, cookieOptions);
        $cookies.put('booking_productGroupName', bookingData.webVisualizationGroupName, cookieOptions);
        $cookies.put('booking_price', bookingData.price, cookieOptions);
        $cookies.put('booking_tax', bookingData.tax, cookieOptions);
        $cookies.put('booking_propertyName', bookingData.propertyName, cookieOptions);
    }

    $scope.submitBookingPayTpv = function () {

        $scope.cardDenied = false;
        alertsService.reset();
        $scope.payment_form_submitted = true;

        const linesInBudget = this.calculatedPrices.totalPrice.lines.filter((line) => line.class === 'LINE');
        if (linesInBudget.length === 0) {
            alertsService.new('bookingForm', 'error', gettextCatalog.getString('Product without stock'));
            return;
        }

        if ($scope.payment_form.$valid) {

            if ($scope.paymentMethod === "CREDIT_CARD" || $scope.paymentMethod === "CREDITCARD_TPVPC" || $scope.paymentMethod === "CREDITCARD_SAVE") {
                if (!masterUtils.checks.checkCreditCard($scope.booking.payment.ccNumber).valid) {
                    alertsService.new('bookingForm', 'error', gettextCatalog.getString("Credit card number is in invalid format."));
                    return;
                }
                $scope.booking.payment.ccHolder = $scope.booking.payment.firstName + ' ' + $scope.booking.payment.lastName;
            }

            $scope.booking.totalAmount = $scope.calculatedPrices.totalImport;
            $scope.booking.downPayment = $scope.calculatedPrices.paymentImport;

            $scope.booking.form = $scope.bookingForm.form;


            delete $scope.booking.payment.method;

            if (!$scope.booking.externalTrackingCode) {
                if ($cookies.get("track")) {
                    $scope.booking.externalTrackingCode = $cookies.get("track");
                } else {
                    $scope.booking.externalTrackingCode = document.referrer;
                }
            }

            loadingService.enable('principal', gettextCatalog.getString("Reserving"), true);

            if (reserving) return;
            reserving = true;

            const tmpBooking = angular.copy($scope.booking);
            if (tmpBooking.payment && tmpBooking.payment.ccNumber) {
                tmpBooking.payment.ccNumber = '************' + tmpBooking.payment.ccNumber.substr(tmpBooking.payment.ccNumber.length - 4, 4);
            }
            if (typeof ma_ut !== "undefined") {
                ma_ut.post({
                    action: "beforeBooking",
                    message: "Before post booking",
                    booking: tmpBooking
                });
            }

            var tempBooking = _.cloneDeep($scope.booking);
            _.each(tempBooking.form.sections, function (section) {
                delete section.counter;
            });

            const price = $scope.calculatedPrices.totalImport.toFixed(2);
            const touristTax = $scope.calculatedPrices.touristicTax;
            const priceWithoutTouristTax = $scope.calculatedPrices.totalImport - touristTax;
            const bookingTax = (priceWithoutTouristTax / 1.1) * (0.1);
            const tax = (bookingTax + touristTax).toFixed(2);

            saveBookingDataInCookies({
                lang: tempBooking.lang,
                propertyName: $scope.info.name,
                price,
                tax,
                productName: $scope.product.name,
                webVisualizationGroupName: $scope.wvg != null ? $scope.wvg.name : $scope.product.name
            });

            $cookies.put('booking_ecommerce_send', 'false');

            $scope.info.rgpd
                .filter(rgpd => ($scope.product.isExperience) ? rgpd.apply_experiences : rgpd.apply_master_booking)
                .forEach(rgpd => {
                    if (tempBooking.rgpd[rgpd.idConsent] == null) {
                        tempBooking.rgpd[rgpd.idConsent] = false;
                    }
                });

            if (typeof dataLayer !== 'undefined' && Array.isArray(dataLayer)) {
                if (myConfig.GoogleTagManagerVersion == 2) {
                    dataLayer.push({ecommerce: null});
                    const replaceData = {price, tax};
                    const product = Object.assign({}, $scope.product, {facilityIds: $state.params.facilityIds});
                    const gtmProduct = Bookings.processGtmProduct(product, null, replaceData);
                    dataLayer.push({
                        event: 'begin_checkout',
                        ecommerce: {
                            paymentMethod: $scope.paymentMethod,
                            currency: 'EUR',
                            value: price,
                            items: [gtmProduct]
                        }
                    });
                }
            }

            Bookings.postBooking(tempBooking, true).then(function (result) {

                if (result) {
                    $cookies.put('booking_idBooking', result.idBooking);

                    if (result.url) {
                        window.location.replace(result.url);
                    } else {

                        /** Construccion del form al tpv */
                        var formTpv = document.createElement("form");
                        formTpv.setAttribute('method', "post");
                        // formTpv.setAttribute('target',"_blank");
                        formTpv.setAttribute('action', result.uri);

                        var inpMarchantParameters = document.createElement("input");
                        inpMarchantParameters.setAttribute('type', "hidden");
                        inpMarchantParameters.setAttribute('name', "Ds_MerchantParameters");
                        inpMarchantParameters.setAttribute('value', result.Ds_MerchantParameters);

                        var inpSignature = document.createElement("input");
                        inpSignature.setAttribute('type', "hidden");
                        inpSignature.setAttribute('name', "Ds_Signature");
                        inpSignature.setAttribute('value', result.Ds_Signature);

                        var inpSignatureVersion = document.createElement("input");
                        inpSignatureVersion.setAttribute('type', "hidden");
                        inpSignatureVersion.setAttribute('name', "Ds_SignatureVersion");
                        inpSignatureVersion.setAttribute('value', result.Ds_SignatureVersion);

                        formTpv.appendChild(inpMarchantParameters);
                        formTpv.appendChild(inpSignature);
                        formTpv.appendChild(inpSignatureVersion);
                        document.getElementById('hiddenTPV').appendChild(formTpv);
                        formTpv.submit();
                    }
                }
            }, processError);


        } else {
            $window.scrollTo(0, 0);
        }

    };

    $scope.submitBookingForm = function () {
        $scope.cardDenied = false;
        alertsService.reset();
        $scope.payment_form_submitted = true;

        const linesInBudget = this.calculatedPrices.totalPrice.lines.filter((line) => line.class === 'LINE');
        if (linesInBudget.length === 0) {
            alertsService.new('bookingForm', 'error', gettextCatalog.getString('Product without stock'));
            return;
        }

        if ($scope.payment_form.$valid) {

            if ($scope.paymentMethod === "CREDIT_CARD" || $scope.paymentMethod === "CREDITCARD_TPVPC" || $scope.paymentMethod === "CREDITCARD_SAVE") {
                if (!masterUtils.checks.checkCreditCard($scope.booking.payment.ccNumber).valid) {
                    alertsService.new('bookingForm', 'error', gettextCatalog.getString("Credit card number is in invalid format."));
                    return;
                }
                $scope.booking.payment.ccHolder = $scope.booking.payment.firstName + ' ' + $scope.booking.payment.lastName;
            }

            $scope.booking.totalAmount = $scope.calculatedPrices.totalImport;
            $scope.booking.downPayment = $scope.calculatedPrices.paymentImport;

            $scope.booking.form = $scope.bookingForm.form;

            delete $scope.booking.payment.method;

            if (!$scope.booking.externalTrackingCode) {
                if ($cookies.get("track")) {
                    $scope.booking.externalTrackingCode = $cookies.get("track");
                } else {
                    $scope.booking.externalTrackingCode = document.referrer;
                }
            }

            loadingService.enable('principal', gettextCatalog.getString("Reserving"), true);

            if (reserving) return;
            reserving = true;

            const tmpBooking = angular.copy($scope.booking);
            if (tmpBooking.payment && tmpBooking.payment.ccNumber) {
                tmpBooking.payment.ccNumber = '************' + tmpBooking.payment.ccNumber.substr(tmpBooking.payment.ccNumber.length - 4, 4);
            }
            if (typeof ma_ut !== "undefined") {
                ma_ut.post({
                    action: "beforeBooking",
                    message: "Before post booking",
                    booking: tmpBooking
                });
            }

            const tempBooking = _.cloneDeep($scope.booking);
            _.each(tempBooking.form.sections, function (section) {
                delete section.counter;
            });

            // TODO Revisar uso de luckyorange
            if (window._loq) {
                window._loq.push(["tag", "Submit form"]);
                window._loq.push(["tag", "Start " + $scope.paymentMethod]);
            }

            if (typeof smartlook === "function") {
                smartlook('tag', 'Action', 'Submit form');
                smartlook('tag', 'Action', "Start " + $scope.paymentMethod);
            }

            if (tempBooking.payment && tempBooking.payment.ccNumber) {
                tempBooking.payment.ccNumber = tempBooking.payment.ccNumber.replace(new RegExp(" ", "g"), "");
                tempBooking.payment.ccNumber = tempBooking.payment.ccNumber.replace(new RegExp("-", "g"), "");
            }

            $scope.info.rgpd
                .filter(rgpd => ($scope.product.isExperience) ? rgpd.apply_experiences : rgpd.apply_master_booking)
                .forEach(rgpd => {
                    if (tempBooking.rgpd[rgpd.idConsent] == null) {
                        tempBooking.rgpd[rgpd.idConsent] = false;
                    }
                });

            if (typeof dataLayer !== 'undefined' && Array.isArray(dataLayer)) {
                if (myConfig.GoogleTagManagerVersion == 2) {
                    dataLayer.push({ecommerce: null});
                    const price = $scope.calculatedPrices.totalImport.toFixed(2);
                    const touristTax = $scope.calculatedPrices.touristicTax;
                    const priceWithoutTouristTax = $scope.calculatedPrices.totalImport - touristTax;
                    const bookingTax = (priceWithoutTouristTax / 1.1) * (0.1);
                    const tax = (bookingTax + touristTax).toFixed(2);
                    const replaceData = {price, tax};
                    const product = Object.assign({}, $scope.product, {facilityIds: $state.params.facilityIds});
                    const gtmProduct = Bookings.processGtmProduct(product, null, replaceData);
                    dataLayer.push({
                        event: 'begin_checkout',
                        ecommerce: {
                            currency: 'EUR',
                            paymentMethod: $scope.paymentMethod,
                            value: price,
                            items: [gtmProduct]
                        }
                    });
                }
            }

            Bookings.postBooking(tempBooking).then(function (result) {
                if (result) {
                    let tmpResult = angular.copy(result);
                    delete tmpResult.budget;
                    delete tmpResult.budgetPayNow;
                    delete tmpResult.budgetPayAtCheckin;
                    delete tmpResult.budgetPayEnd;

                    const price = $scope.calculatedPrices.totalImport.toFixed(2);
                    const touristTax = $scope.calculatedPrices.touristicTax;
                    const priceWithoutTouristTax = $scope.calculatedPrices.totalImport - touristTax;
                    const bookingTax = (priceWithoutTouristTax / 1.1) * (0.1);
                    const tax = (bookingTax + touristTax).toFixed(2);

                    window.propertyName = result.propertyName;
                    window.productName = result.productName;
                    window.webVisualizationGroupName = result.webVisualizationGroupName;
                    window.bookingLanguage = result.lang;
                    window.idBooking = result.idBooking;
                    window.price = price;
                    window.touristTax = touristTax;
                    window.bookingTax = bookingTax;
                    window.tax = tax;

                    // TODO Refactorizar, codigo duplicadp
                    saveBookingDataInCookies(Object.assign({price, tax}, result));

                    if (typeof ma_ut !== "undefined") {
                        ma_ut.post({
                            action: "completeBooking",
                            message: "Complete booking",
                            booking: tmpResult
                        });
                    }

                    const enhanced_conversion_data = {
                        email: result.reservationHolder.email,
                        home_address: {}
                    };
                    if (result.reservationHolder.firstName) {
                        enhanced_conversion_data.first_name = result.reservationHolder.firstName;
                    }
                    if (result.reservationHolder.lastName) {
                        enhanced_conversion_data.last_name = result.reservationHolder.lastName;
                    }
                    if (result.reservationHolder.phone) {
                        enhanced_conversion_data.phone_number = result.reservationHolder.phone;
                    }
                    if (result.reservationHolder.address) {
                        enhanced_conversion_data.home_address.street = result.reservationHolder.address;
                    }
                    if (result.reservationHolder.city) {
                        enhanced_conversion_data.home_address.city = result.reservationHolder.city;
                    }
                    if (result.reservationHolder.zip) {
                        enhanced_conversion_data.home_address.postal_code = result.reservationHolder.zip;
                    }
                    if (result.reservationHolder.country) {
                        enhanced_conversion_data.home_address.country = result.reservationHolder.country;
                    }
                    //         home_address: {
                    //           region: 'CA', ¿?
                    //         }

                    for (const key in enhanced_conversion_data) {
                        if (key === 'home_address' && enhanced_conversion_data[key] != null) {
                            for (const key2 in enhanced_conversion_data[key]) {
                                enhanced_conversion_data[key][key2] = enhanced_conversion_data[key][key2].toString();
                                enhanced_conversion_data[key][key2] = reemplazarCaracteresNoLatinos(enhanced_conversion_data[key][key2]);
                            }
                        } else {
                            enhanced_conversion_data[key] = enhanced_conversion_data[key].toString();
                            enhanced_conversion_data[key] = reemplazarCaracteresNoLatinos(enhanced_conversion_data[key]);
                        }
                        if (['email', 'phone'].includes(key)) {
                            enhanced_conversion_data[key] = enhanced_conversion_data[key].replace(/\s/g, '').toLowerCase();
                        }
                    }

                    if (myConfig.trackingId && typeof ga !== "undefined") {
                        ga('require', 'ecommerce');
                        ga('ecommerce:addTransaction', {
                            'id': result.idBooking,                     // Transaction ID. Required.
                            'affiliation': result.propertyName,   // Affiliation or store name.
                            'revenue': price,               // Grand Total.
                            'tax': tax,
                            'currency': 'EUR'
                        });
                        ga('ecommerce:addItem', {
                            'id': result.idBooking,                     // Transaction ID. Required.
                            'name': result.productName,        // Product name. Required.
                            'sku': result.idProduct,                 // SKU/code.
                            'category': result.webVisualizationGroupName,         // Category or variation.
                            'price': price,                 // Unit price.
                            'quantity': '1',                   // Quantity.
                            'currency': 'EUR'
                        });
                        ga('ecommerce:send');
                    }

                    if (myConfig.googleGlobalTag && typeof gtag !== "undefined") {
                        gtag('event', 'purchase', {
                            currency: 'EUR',
                            transaction_id: result.idBooking,
                            affiliation: result.propertyName,
                            tax: tax,
                            value: price,
                            items: [
                                {
                                    item_id: result.idProduct,
                                    id: result.idBooking,
                                    sku: result.idProduct,
                                    name: result.productName,
                                    category: result.webVisualizationGroupName,
                                    price: price,
                                    quantity: 1,
                                    currency: 'EUR'
                                }
                            ]
                        });
                    }

                    if (myConfig.facebookPixelCode && typeof fbq !== "undefined") {
                        fbq('track', 'Purchase', {
                            value: price,
                            currency: 'EUR',
                            content_name: result.productName,
                            content_ids: [result.idProduct, result.idBooking],
                            content_type: result.webVisualizationGroupName,
                        });
                    }

                    if (typeof dataLayer !== 'undefined' && Array.isArray(dataLayer)) {

                        if (myConfig.GoogleTagManagerVersion == null || myConfig.GoogleTagManagerVersion == 1) {
                            dataLayer.push({
                                'event': 'transaction',
                                'ecommerce': {
                                    'purchase': {
                                        'actionField': {
                                            'id': result.idBooking,                  // Transaction ID. Required for purchases and refunds.
                                            'affiliation': result.propertyName,
                                            'revenue': price,                // Total transaction value (incl. tax and shipping)
                                            'tax': tax,
                                            'currency': 'EUR'
                                        },
                                        'products': [{                             // List of productFieldObjects.
                                            'name': result.productName,              // Name or ID is required.
                                            'id': result.idProduct,
                                            'price': price,
                                            'category': result.webVisualizationGroupName,
                                            'quantity': 1,                          // Optional fields may be omitted or set to empty string.,
                                            'currency': 'EUR'
                                        }]
                                    }
                                }
                            });
                        }

                        const product = Object.assign({}, $scope.product, {facilityIds: $state.params.facilityIds});
                        const gtmProduct = Bookings.processGtmProduct(product, null, {
                            price, tax,
                            item_name: result.productName,
                            item_brand: result.webVisualizationGroupName
                        });
                        const ecommerceData = {
                            currency: 'EUR',
                            transaction_id: result.idBooking,
                            affiliation: result.propertyName,
                            tax: parseFloat(tax),
                            value: parseFloat(price),
                            items: [gtmProduct]
                        };

                        if (myConfig.GoogleTagManagerVersion == 2 && !myConfig.purchase_enhanced_conversion) {
                            dataLayer.push({ecommerce: null});
                            dataLayer.push({
                                event: 'purchase',
                                ecommerce: ecommerceData
                            });
                        }

                        if (myConfig.purchase_enhanced_conversion) {
                            Promise.all([
                                enhanced_conversion_data.email != null ? sha256(enhanced_conversion_data.email) : null,
                                enhanced_conversion_data.phone_number != null ? sha256(enhanced_conversion_data.phone_number) : null,
                                enhanced_conversion_data.first_name != null ? sha256(enhanced_conversion_data.first_name) : null,
                                enhanced_conversion_data.last_name != null ? sha256(enhanced_conversion_data.last_name) : null
                            ])
                                .then(function (values) {
                                    enhanced_conversion_data.sha256_email_address = values[0];
                                    enhanced_conversion_data.sha256_phone_number = values[1];
                                    enhanced_conversion_data.sha256_first_name = values[2];
                                    enhanced_conversion_data.sha256_last_name = values[3];

                                    if (myConfig.GoogleTagManagerVersion == null || myConfig.GoogleTagManagerVersion == 1) {
                                        dataLayer.push({
                                            event: 'purchase_enhanced_conversion',
                                            order_value: price,
                                            order_id: result.idBooking,
                                            currencyCode: 'EUR',
                                            enhanced_conversion_data
                                        });
                                    }
                                    if (myConfig.GoogleTagManagerVersion == 2) {
                                        dataLayer.push({ecommerce: null});
                                        dataLayer.push({
                                            event: 'purchase',
                                            ecommerce: Object.assign(ecommerceData, enhanced_conversion_data)
                                        });
                                    }
                                })
                                .catch(function (error) {
                                    console.log(error);
                                });
                        }

                        if (myConfig.GoogleTagManagerVersion == null || myConfig.GoogleTagManagerVersion == 1) {
                            dataLayer.push({
                                event: 'bookingCompleted',
                                propertyName: result.propertyName,
                                productName: result.productName,
                                productGroupName: result.webVisualizationGroupName,
                                bookingLanguage: result.lang,
                                idBooking: result.idBooking,
                                price,
                                tax
                            });
                        }

                    }

                    $cookies.remove('booking_ecommerce_send');
                    $state.go('booking', {cryptedIdBooking: result.cryptedIdBooking});
                    loadingService.disable('principal');

                    if (window._loq) {
                        window._loq.push(["tag", "Booking Finished", true]);
                        window._loq.push(["tag", result.idBooking]);
                    }

                    if (typeof smartlook === "function") {
                        smartlook('tag', 'idBooking', result.idBooking);
                        smartlook('tag', 'Action', "Booking " + result.idBooking + " finished");
                    }

                }
            }, processError);
        } else {
            $window.scrollTo(0, 0);
        }
    };

    $scope.showSectionHeader = function (section) {
        return section.additionalConcepts.some((concept) => concept.type !== 'COMMENTS_BOX');
    };

    function setFormData(data) {
        if (data.holder != null) {
            for (const input of $scope.bookingForm.form.sections.holder.inputs) {
                const inputName = input.name;
                if (inputName === 'emailConfirmation') {
                    continue;
                }
                if (data.holder[inputName] != null) {
                    input.value[0] = data.holder[inputName];
                }
            }
        }
        if (data.vehicles != null) {
            let counter = 0;
            for (const vehicle of data.vehicles) {
                for (const input of $scope.bookingForm.form.sections.vehicles.inputs) {
                    const inputName = input.name;
                    if (vehicle[inputName] != null) {
                        input.value[counter] = vehicle[inputName];
                    }
                }
                counter++;
            }
        }
        if (data.guest != null) {
            let counter = 0;
            for (const guest of data.guest) {
                for (const input of $scope.bookingForm.form.sections.guest.inputs) {
                    const inputName = input.name;
                    if (guest[inputName] != null) {
                        input.value[counter] = guest[inputName];
                    }
                }
                counter++;
            }
        }
        if (data.comments != null) {
            $scope.bookingForm.form.sections.comments.inputs[0].value[0] = data.comments[0].comments;
        }
    }

    function processFormForEdm() {
        for (const input of $scope.bookingForm.form.sections.holder.inputs) {
            const inputName = input.name;
            if (inputName === 'email' || inputName === 'lastName') {
                input.readonly = true;
            }
        }
    }

    function loadFormFromBookingData(cryptoId) {
        Bookings.getReservationData(cryptoId)
            .then(function (result) {
                if (result != null) {
                    setFormData(result.data);
                    if (result.additionalConcepts != null) {
                        for (let additionalConceptId in result.additionalConcepts) {
                            additionalConceptId = parseInt(additionalConceptId);
                            $scope.booking.additionalConcepts[additionalConceptId] = result.additionalConcepts[additionalConceptId];
                        }
                    }
                }
            })
            .catch(function (err) {
                console.error(err);
            });
    }

}
